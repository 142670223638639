export default {
  "code": "nl",
  "title": "Nederlands",
  "flag": "🇳🇱",
  "meta": {
    "index": {
      "title": "Betere scripties.\n10 keer sneller met AI.",
      "description": "Zonder Hesse: Je staart urenlang naar een lege pagina.\nMet Hesse: Je genereert geweldige teksten met één druk op de knop."
    },
    "profile": {
      "title": "Profielinstellingen",
      "description": "Beheer eenvoudig je profielinstellingen"
    }
  },
  "validations": {
    "required": "{_field_} is een verplicht veld",
    "min": "{_field_} moet minimaal {length} tekens lang zijn",
    "max": "{_field_} mag niet langer zijn dan {length} tekens"
  },
  "buttons": {
    "close": "Sluiten",
    "upgrade": "Upgraden",
    "get_more_words": "Meer woorden krijgen",
    "login": "Inloggen",
    "logout": "Uitloggen",
    "cancel": "Annuleren",
    "yes": "Ja",
    "no": "Nee",
    "tryout": "Uitproberen",
    "add": "Toevoegen",
    "report": "Rapporteren",
    "save": "opslaan",
    "edit": "bewerken",
    "copy": "Kopiëren",
    "delete": "verwijderen",
    "generate": "Genereren",
    "signup": "Registreren",
    "continue": "Doorgaan",
    "submit": "Verzenden",
    "add_source": "Bron Toevoegen",
    "select_source": "Selecteer Bron",
    "new_document": "Nieuw Document",
    "back": "Terug",
    "local_document": {
      "title": "Een Document van mijn Computer",
      "type": "Upload Document"
    },
    "web_document": {
      "title": "Heb je een Link naar een Document?",
      "type": "Web Link/Document"
    },
    "done": "klaar",
    "discard": "verwijderen"
  },
  "labels": {
    "missing_subscription": "Je hebt een abonnement nodig om deze tool te gebruiken.",
    "booked": "je huidige plan",
    "account": "Account",
    "subscription": "Abonnement",
    "name": "Naam",
    "email": "E-mail",
    "limit": "Limiet",
    "settings": "Instellingen",
    "words": "Woorden",
    "usage": "Gebruik",
    "extra_words": "incl. {words} extra woorden",
    "show_all_applications": "Toon alle applicaties",
    "show_all": "Alles tonen",
    "start_page": "Startpagina",
    "is_favorite": "In favorieten",
    "add_favorite": "Toevoegen aan favorieten",
    "wordlimit_reached": "Woordlimiet bereikt",
    "wordlimit_increase": "Woordlimiet verhogen",
    "contact": "Contact",
    "history": "Geschiedenis",
    "show_history": "Geschiedenis tonen",
    "inprint": "Colofon",
    "dataregulation": "Gegevensbescherming",
    "tos": "Gebruiksvoorwaarden",
    "month": "maand",
    "cancel_anytime": "Altijd annuleren",
    "try_now": "Nu gratis proberen",
    "book_again": "Opnieuw boeken",
    "book": "Plan wijzigen",
    "upgrade_to": "Upgraden naar {product}",
    "copied_link": "Link gekopieerd",
    "subject": "Onderwerp",
    "generated_by_hesse": "Gegenereerd door Hesse in 3 seconden.",
    "use_template": "Sjabloon gebruiken",
    "show_mode": "Meer weergeven",
    "upload": "Uploaden",
    "optional": "optioneel",
    "my_documents": "Mijn Documenten",
    "my_sources": "Mijn Bronnen",
    "add_source": "Bron Toevoegen",
    "select_source": "Selecteer Bron",
    "page": "Bladzijde {page}",
    "pages": "Bladzijden {from} - {to}",
    "unknown_author": "Onbekende auteur",
    "checker_results": "AI-controle Resultaten",
    "human": "menselijk",
    "citation_style": "Citaatiestijl",
    "started_at": "gestart op",
    "ends_at": "eindigt op",
    "cancel_subscription": "abonnement opzeggen",
    "file_too_large": "Bestand te groot (max. 5 MB), probeer het te splitsen",
    "relevance": "Relevantie",
    "relevance_help": "Relevantie meet hoe goed de bron past bij het gezochte onderwerp in termen van inhoud.",
    "quality": "Kwaliteit",
    "quality_help": "Kwaliteit meet hoe goed de bron is in zowel inhoud als vorm.",
    "trustworthiness": "Betrouwbaarheid",
    "trustworthiness_help": "Betrouwbaarheid meet, voornamelijk op basis van de metadata van de bron, hoe betrouwbaar deze is.",
    "high": "hoog",
    "normal": "normaal",
    "low": "laag",
    "features": "Functies",
    "monthly": "Maandelijks",
    "year": "jaar",
    "yearly": "Jaarlijks",
    "save_50": "bespaar 50%",
    "pay_amount_per_period": "Betaal {amount}/{period}.",
    "focus_of_the_chapter": "Focus van het hoofdstuk",
    "reason": "Reden",
    "summary": "Samenvatting",
    "raw_text": "Ruw Tekst",
    "topic": "Thema",
    "essay_banner_part_1": "🚨 <i>Deze functie wordt binnenkort uitgeschakeld</i><br/> Voor <strong>betere generatie resultaten</strong> en <strong>meer gedetailleerde controle</strong>, raden we aan om de",
    "essay_banner_part_2": "tool te gebruiken en je essay <strong>hoofdstuk per hoofdstuk</strong> te genereren. Probeer het voor een soepelere en persoonlijkere schrijfervaring! ✨📖"
  },
  "blog": {
    "written_by": "Geschreven door",
    "read_article": "Artikel lezen"
  },
  "source": {
    "select": {
      "empty": {
        "headline": "Hier is nog niets.",
        "hint": "Je kunt bronnen markeren in 'Bronnen Vinden' en ze gebruiken in verschillende tools."
      }
    },
    "table": {
      "name": "Naam",
      "type": "Type",
      "created_at": "Gemaakt op"
    },
    "type": {
      "document": "Document",
      "article": "Artikel",
      "paper": "Papier"
    },
    "hint": {
      "not_usable": "Bronnen die nog niet zijn verwerkt, worden niet meegenomen bij het schrijven."
    },
    "processing": "wordt verwerkt...",
    "recent": "recentelijk gebruikt",
    "bookmark": "bladwijzer",
    "bookmarked": "bladwijzer maken",
    "direct_pdf_access": "Directe PDF-toegang",
    "source_info": "Broninfo"
  },
  "placeholders": {
    "url": "https://en.wikipedia.org/wiki/Radiocarbon_dating",
    "topic": "Hervormingen na de Tweede Wereldoorlog",
    "focus_of_the_chapter_legacy": "Politieke hervormingen",
    "focus_of_the_chapter": "Hoe zou je jouw hoofdstuk noemen?"
  },
  "groups": {
    "research": "Research",
    "essay": "Essay",
    "misc_tools": "divers gereedschap"
  },
  "flows": {
    "summarize": {
      "headline": "Samenvatten",
      "description": "Wat wil je samenvatten?"
    },
    "titles": {
      "summarize_document": "Document",
      "summarize_text": "Tekst",
      "summarize_article": "Artikel",
      "summarize_source": "Bron"
    },
    "prefill": {
      "summarize_document": "Samenvatting van het document."
    }
  },
  "messages": {
    "get_more_words": "Meer woorden krijgen",
    "contact_success": "Bedankt voor je bericht!",
    "error": "Er is iets misgegaan. Probeer het later opnieuw.",
    "updated": "{field} bijgewerkt",
    "failed_to_update": "Het bijwerken van \"{field}\" is mislukt, probeer het opnieuw"
  },
  "validation": {
    "required": "{_field_} is verplicht",
    "min": "{_field_} moet minimaal {length} tekens lang zijn",
    "max": "{_field_} mag niet langer zijn dan {length} tekens",
    "maxarray": "{_field_} heeft te veel elementen"
  },
  "domains": {
    "cancellation": {
      "headline": "Abonnement annuleren",
      "description": "Wil je je abonnement echt annuleren? Wat is de reden voor je annulering?",
      "options": [
        {
          "text": "Werk voltooid",
          "value": "work_completed"
        },
        {
          "text": "Niet tevreden met het product",
          "value": "unsatisfied_with_product"
        },
        {
          "text": "Te duur",
          "value": "price_performance_issue"
        },
        {
          "text": "Technische problemen",
          "value": "technical_issues"
        },
        {
          "text": "Ik heb een andere aanbieder gevonden",
          "value": "found_other_provider"
        },
        {
          "text": "Ik wist niet dat het een abonnement was",
          "value": "unaware_of_subscription"
        },
        {
          "text": "Anders",
          "value": "other"
        }
      ]
    },
    "try": {
      "headline": "Hesse Pro",
      "loading": "We activeren je proefaccount..."
    },
    "openai": {
      "status": {
        "unreliable": "We hebben momenteel een verhoogde serverbelasting. Er kunnen uitval zijn. Als er iets niet werkt, probeer het dan later nog eens.",
        "working_on_fix": "We werken momenteel aan een oplossing. Probeer het later nog eens."
      }
    },
    "ai_checker": {
      "hint": "AI checkers zijn tools die teksten controleren op AI-inhoud. Klik op een van de kaarten om naar de bijbehorende tool te gaan."
    },
    "cookie": {
      "headline": "Cookies 🍪",
      "text1": "Door op \"Alle cookies accepteren\" te klikken, stemt u in met het opslaan van cookies op uw apparaat om de navigatie op de website te verbeteren, het gebruik van de website te analyseren en onze marketinginspanningen te ondersteunen.",
      "analytics": "Prestatiecookies",
      "analytics_text": "Deze cookies stellen ons in staat om het gebruikersgedrag op Hesse.ai anoniem te analyseren om de gebruikerservaring te optimaliseren.",
      "marketing_text": "Deze cookies kunnen via onze website door onze advertentiepartners worden ingesteld. Ze kunnen door deze bedrijven worden gebruikt om een profiel van uw interesses op te stellen en u relevante advertenties op andere websites te tonen.",
      "marketing": "Marketingcookies",
      "technical": "Functionele cookies",
      "technical_text": "Deze cookies zijn nodig om een veilige en betrouwbare werking te garanderen.",
      "consent": "Alle cookies accepteren",
      "save": "Voorkeuren opslaan",
      "decline": "Weigeren"
    },
    "write": {
      "confirm_delete_paragraph": "Paragraaf echt verwijderen?",
      "save_changes": "Wijzigingen opslaan",
      "keep_writing": "Verder schrijven"
    },
    "feedback": {
      "title": "Geef ons extra feedback",
      "description": "We proberen voortdurend onze resultaten te verbeteren. Beschrijf wat je graag veranderd zou willen zien."
    },
    "report": {
      "title": "Deze reactie echt melden?",
      "reason": "Reden van melding",
      "description": "Meld resultaten die kritische of illegale inhoud bevatten.",
      "result_good": "Resultaat is nuttig",
      "result_bad": "Resultaat is niet nuttig"
    },
    "pricing": {
      "monthly_wordlimit": "{limit} woorden per maand",
      "increase_limit": "Limiet verhogen",
      "wordlimit_reached": "Je hebt de woordlimiet bereikt",
      "wordlimit_reached_description": "Het quotum voor deze maand is bereikt. Als je door wilt gaan,\ndan moet je je limiet verhogen.",
      "wordlimit_nearly_reached": "Je woorden zijn bijna op.",
      "get_more_words": "Meer woorden krijgen",
      "get_more_words_description": "Goede teksten vereisen veel rekenkracht. Dat is duur.<br /> Maar we hebben onze pakketten zo betaalbaar mogelijk gemaakt.",
      "error_title": "De boeking is helaas niet gelukt.",
      "error_description": "Neem contact met ons op - We zullen onmiddellijk helpen.",
      "text1": "Hoeveel zijn 10.000 woorden?",
      "text2": "Dat waren 10.000 woorden",
      "referral_title": "Kun je nog niet beslissen?",
      "referral_title2": "Meer woorden nodig?",
      "referral_description": "Deel je link met vrienden. Je krijgt 1.000 gratis woorden voor elke nieuwe aanmelding via jouw link."
    },
    "survey": {
      "title": "Hoe beoordeel je Hesse.ai?",
      "suggestions": "Wat zou je veranderen?",
      "success_title": "Bedankt voor je feedback!",
      "success_text": "Je helpt ons om Hesse.ai continu te verbeteren",
      "abort": "niet beoordelen"
    },
    "cards_hint": {
      "title": "Kaarten zijn nu beschikbaar",
      "subtitle": "Automatisch <strong>kaarten</strong> maken van je <strong>lezing</strong>?",
      "cta": "Nu kaarten maken"
    },
    "share_modal": {
      "headline": "Hesse delen - gratis woorden krijgen"
    },
    "login_page": {
      "loading": "Je wordt ingelogd...",
      "error": "De inlogpoging is mislukt",
      "delayed": "Het inloggen duurt langer dan normaal.<br /> Controleer je internetverbinding en probeer het indien nodig opnieuw.",
      "try_again": "Opnieuw inloggen"
    },
    "sidebar": {
      "create_own_application": "Eigen toepassing maken",
      "hesse_cards": "Hesse Cards 🃏",
      "all_applications": "Alle toepassingen",
      "favourites": "Mijn favorieten"
    },
    "profile": {
      "title": "Profielinstellingen",
      "limit": "Limiet",
      "used_tokens": "Gebruikte tokens",
      "get_more_words": "Meer woorden krijgen",
      "no_subscription": "Je hebt nog geen abonnement.",
      "upgrade_now": "Nu upgraden",
      "manage_subscription": "Abonnement beheren",
      "delete_account": "Account verwijderen",
      "deleted": "Je account is verwijderd. Als je inlogt, wordt er een nieuw account aangemaakt."
    },
    "contact": {
      "title": "Contact",
      "subtitle": "Neem contact met ons op"
    },
    "error_page": {
      "title404": "404 - Pagina niet gevonden",
      "title": "Er is een fout opgetreden",
      "text": "Help mee om Hesse.ai te verbeteren.<br /> Beschrijf kort wat je hebt gedaan voordat deze pagina verscheen.",
      "cta": "Naar de startpagina"
    },
    "success_page": {
      "loading": "laden...",
      "booking_success": "Je reservering is gelukt!",
      "booked_product": "Je hebt {productName} geboekt",
      "start_now": "Nu beginnen"
    }
  },
  "typewriter": {
    "writeEssay": [
      "Teksten worden gegenereerd...",
      "Teksten worden gecodeerd...",
      "Plagiaatcontrole...",
      "AI checker tools uitvoeren...",
      "Teksten niet meer traceerbaar ✔"
    ],
    "writeOutline": [
      "Teksten worden gegenereerd...",
      "Teksten worden gecodeerd...",
      "Plagiaatcontrole...",
      "AI checker tools uitvoeren...",
      "Teksten niet meer traceerbaar ✔"
    ],
    "writeIntroduction": [
      "Teksten worden gegenereerd...",
      "Teksten worden gecodeerd...",
      "Plagiaatcontrole...",
      "AI checker tools uitvoeren...",
      "Teksten niet meer traceerbaar ✔"
    ]
  },
  "loader": [
    "Jouw invoer wordt geëvalueerd",
    "Zout en peper toevoegen",
    "Pedro, Pedro, Pedro",
    "Magische toverstaf schudden",
    "Naar jouw tafel brengen"
  ],
  "tools": {
    "findSources": "Bronnen Vinden",
    "writeOutline": "Opzet Maken",
    "writeChapter": "Schrijf een hoofdstuk",
    "writeEssay": "Schrijf een essay"
  }
}
